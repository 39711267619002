html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

html {
  /* Set default font family to sans-serif */
  font-family: sans-serif;
  /* Prevent iOS text size adjust after orientation change, without disabling */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a {
  background-color: transparent;
  &:active, &:hover {
    outline: 0;
  }
}

b, strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

strike {
  text-decoration: line-through;
}

small {
  font-size: 80%;
}

img {
  border: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* html5 */

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

/* forms */

button, input, optgroup, select, textarea {
  margin: 0;
  outline: 0;
  font: inherit;
  color: inherit;
}

button {
  border: 0;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: none;
}

select {
  text-transform: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &::-ms-clear {
    display: none;
  }
}

textarea {
  overflow: auto;
}

/* ---- 1.2 Basic ---- */

body {
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 2;
  background-color: #1C2321;
  color: #fff;
  overflow-x: hidden;
  word-wrap: break-word;
  /* font smoothing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* optimize rendering text */
  text-rendering: optimizeLegibility;
  /* smooth the scrolling on touch devices - webkit browsers */
  -webkit-overflow-scrolling: touch;
  /* animation colorful */
  -webkit-animation: colorful 60s infinite ease-in;
  -moz-animation: colorful 60s infinite ease-in;
  animation: colorful 60s infinite ease-in;
}

/* ---- 1.3 Framework ---- */

.container {
  width: 1130px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  &:before {
    display: table;
    content: '';
  }
}

.clearfix:before, .container:after, .clearfix:after {
  display: table;
  content: '';
}

.container:after, .clearfix:after {
  clear: both;
}

.col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

/* @media = 1130px */
@media only screen and (max-width: 1130px) {
  .container, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1 {
    width: 100%;
  }

  .mobile-hidden {
    display: none;
  }
}

.hidden {
  display: none;
}

.pull-lt {
  float: left;
}

.pull-rt {
  float: right;
}

.lt {
  text-align: left;
}

.ct {
  text-align: center;
}

.rt {
  text-align: right;
}

/* nav */

.nav {
  > li {
    position: relative;
    display: block;
    > a {
      position: relative;
      display: block;
    }
  }
  &.nav-horizontal {
    &:before {
      display: table;
      content: '';
    }
    &:after {
      display: table;
      content: '';
      clear: both;
    }
    > li {
      float: left;
      &:last-child a {
        margin-right: 0;
      }
    }
  }
  &.nav-inline > li {
    display: inline-block;
    &:last-child a {
      margin-right: 0;
    }
  }
}

/* nav > nav-horizontal */

/* nav > nav-inline */

/* ---- 1.4 Typography ---- */

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 13px;
}

p {
  font-weight: 400;
  line-height: 2;
}

/* ---- 1.5 Links ---- */

a {
  text-decoration: none;
  color: #2ecc71;
  &:hover {
    text-decoration: underline;
  }
  &.button:hover {
    text-decoration: none;
  }
}

/* ---- 1.6 Buttons ---- */

.button {
  background-color: transparent;
  background-image: none;
  display: inline-block;
  padding: 12px;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  line-height: 2;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;
  text-rendering: auto;
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  -ms-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* button default */

.button-default {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  &:hover {
    background-color: #fff;
    color: #13151a;
  }
}

/* button primary */

.button-primary {
  background-color: #13151a;
  color: #fff;
  &:hover {
    background-color: #fff;
    color: #13151a;
  }
}

/* ---- 2.1 Wrapper ---- */

html, body {
  height: 100%;
}

.wrapper {
  position: relative;
  > .container {
    position: relative;
    z-index: 2;
  }
}

/* ---- 2.2 Header ---- */

.header-primary {
  margin-top: 60px;
  padding: 8px 0;
  text-align: center;
  > a {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 0.6;
      filter: alpha(opacity=60);
    }
  }
}

/* ---- 2.3 Content ---- */

.content-primary {
  width: 500px;
  margin: 0 auto;
  padding: 180px 0 224px 0;
  text-align: center;
  & > {
    h1, h2, h3 {
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      color: #fff;
    }
  }
  .section-footer {
    margin-top: 40px;
    .button {
      margin: 0 8px;
      min-width: 160px;
    }
  }
}

/* @media = 500px */
@media only screen and (max-width: 500px) {
  .content-primary {
    width: 100%;
  }
}

/* @media = 500px */
@media only screen and (max-width: 500px) {
  .content-primary .section-footer .button {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* ---- 2.4 Footer ---- */

.footer-primary {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
  .links {
    display: inline-block;
    > li {
      padding: 0 10px;
      width: 220px;
      > a {
        background-color: transparent;
        background-image: none;
        padding-top: 18px;
        padding-left: 76px;
        padding-bottom: 18px;
        border-width: 0;
        border-radius: 3px;
        font-size: 14px;
        line-height: 2;
        text-align: left;
        color: #fff;
        -webkit-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        -moz-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        -ms-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        -o-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          text-decoration: none;
        }
        > i {
          position: absolute;
          top: 0;
          left: 0;
          width: 64px;
          height: 64px;
          font-size: 42px;
          line-height: 64px;
          text-align: center;
        }
      }
    }
  }
}

/* @media = 1130px */
@media only screen and (max-width: 1130px) {
  .footer-primary .links > li {
    padding: 0 4px;
    width: 56px;
    overflow: hidden;
    > a {
      padding-top: 10px;
      padding-left: 0;
      padding-bottom: 10px;
      text-indent: -9999px;
      > i {
        width: 48px;
        height: 48px;
        font-size: 32px;
        line-height: 48px;
        text-indent: 0;
      }
    }
  }
}